import { deep, swiss } from '@theme-ui/presets'
import nightOwl from "@theme-ui/prism/presets/night-owl.json";

// example theme file
const theme = {
    initialColorModeName: 'dark',
    ...deep,
    colors:{
        ...deep.colors,
        modes:{
            light:{
                ...swiss.colors
            }
        }
    },
    styles: {
      ...deep.styles,
      code: {
          ...nightOwl
      }
    },
    sizes: {
        container: 1024,
    }
  }

  
  export default theme